import { render, staticRenderFns } from "./CostCentreList.vue?vue&type=template&id=9bca983a&scoped=true"
import script from "./CostCentreList.vue?vue&type=script&lang=js"
export * from "./CostCentreList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9bca983a",
  null
  
)

export default component.exports