<template>
    <list-page
        page-title="Cost Centres"
        page-icon="mdi-chart-pie"
        collection-name="costCentre"
        :items-per-page="20"
        add-dialog-title="Add Cost Centre"
        is-filter
    />
</template>

<script>
import ListPage from '../../../components/pages/List';

export default {
    name: 'AccountList',
    components: { ListPage },

};
</script>

<style scoped></style>
